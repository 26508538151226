export const seo = {
  url: 'blog',
  title: {
    pl: 'Sea & Air Blog',
    en: 'Sea & Air Blog',
  },
  desc: {
    pl: `Wszystkie aktualności z życia Omida Sea And Air w jednym miejscu.`,
    en: `All news from the life of Omida Sea And Air in one place.`,
  },
  keywords: [
    'omida',
    'sea and air',
    'sea & air',
    'spedycja',
    'logistyka',
    'transport',
    'blog',
  ],
}

export const intro = {
  title: {
    pl: 'Sea & Air Blog',
    en: 'Sea & Air Blog',
  },
  desc: {
    pl: `Wszystkie aktualności z życia Omida Sea And Air w jednym miejscu.`,
    en: `All news from the life of Omida Sea And Air in one place.`,
  },
}
