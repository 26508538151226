import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { seo, intro } from '../content/blog'
import { useLangContext } from '../context/lang.context'
import Intro from '../components/Intro'
import BlogSection from '../sections/index/BlogSection'
import FormNewsletter from '../components/FormNewsletter'

const Blog = () => {
  const { lang } = useLangContext()

  const { image } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/blog.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
    }
  `)

  const button = {
    text: {
      pl: 'Przeglądaj aktualności',
      en: 'Browse news',
    },
    action: 'SCROLL',
  }

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        keywords={seo.keywords}
      />
      <Intro
        data={{ ...intro, button }}
        image={image.childImageSharp}
        position='bottom'
      />
      <BlogSection />
      <FormNewsletter />
    </Layout>
  )
}

export default Blog
